/* eslint-disable no-console */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import Infrastructure from "@/infrastructure";
import Bridge from "@/infrastructure/bridge/send";
import JWT from "jwt-decode";
import { userRolesJWT } from "@/common/common";
import { backofficeRoutes } from "@/router/backoffice";

const infra = new Infrastructure();
const body = document.body;

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("@/views/main-wrapper/main-wrapper.vue"),
    children: [
      {
        path: "",
        name: "timeline",
        component: () => import("@/views/time-line-view/time-line-view.vue")
      },
      {
        path: "booking",
        name: "booking",
        component: () => import("@/views/booking-view/booking-view.vue")
      },

      {
        path: "maps-booking",
        name: "maps-booking",
        component: () => import("@/views/maps-booking/maps-booking.vue")
      },
      {
        path: ":pathMatch(.*)*",
        name: "404",
        component: () => import("@/views/page-404/page-404.vue")
      },
      {
        path: "filter",
        name: "filter",
        component: () => import("@/views/filter-view/filter-view.vue"),
        meta: {
          forMobile: true
        }
      },
      {
        path: "my-bookings",
        name: "my-bookings",
        component: () =>
          import("@/views/my-bookings-view/my-bookings-view.vue"),
        meta: {
          forMobile: true
        }
      },
      ...backofficeRoutes,
      {
        path: "room-mobile",
        name: "room-mobile",
        component: () =>
          import("@/views/room-mobile-view/room-mobile-view.vue"),
        meta: {
          forMobile: true
        }
      },
      {
        path: "coworking-mobile",
        name: "coworking-mobile",
        component: () =>
          import("@/views/coworking-mobile-view/coworking-mobile-view.vue"),
        meta: {
          forMobile: true
        }
      },
      {
        path: "coworking-mobile-map",
        name: "coworking-mobile-map",
        component: () =>
          import(
            "@/views/coworking-mobile-map-view/coworking-mobile-map-view.vue"
          ),
        meta: {
          forMobile: true
        }
      },
      {
        path: "locker-mobile",
        name: "locker-mobile",
        component: () =>
          import("@/views/locker-mobile-view/locker-mobile-view.vue"),
        meta: {
          forMobile: true
        }
      },
      {
        path: "locker-mobile-map",
        name: "locker-mobile-map",
        component: () =>
          import("@/views/locker-mobile-map-view/locker-mobile-map-view.vue"),
        meta: {
          forMobile: true
        }
      },
      {
        path: "coworking-mobile-booking-out",
        name: "coworking-mobile-booking-out",
        component: () =>
          import(
            "@/views/coworking-mobile-booking-out-view/coworking-mobile-booking-out-view.vue"
          ),
        meta: {
          forMobile: true
        }
      },
      {
        path: "room-mobile-my-booking-details-view",
        name: "room-mobile-my-booking-details-view",
        component: () =>
          import(
            "@/views/room-mobile-my-booking-details-view/room-mobile-my-booking-details-view.vue"
          ),
        meta: {
          forMobile: true
        }
      },
      {
        path: "widget",
        name: "widget",
        component: () => import("@/views/widget/widget.vue")
      },
      {
        path: "maps",
        name: "maps",
        component: () => import("@/views/maps-view/maps-view.vue")
      },
      {
        path: "booking-coworking",
        name: "booking-coworking",
        component: () => import("@/views/coworking/coworking.vue")
      },
      {
        path: "booking-lockers",
        name: "booking-lockers",
        component: () => import("@/views/lockers/lockers.vue")
      },
      {
        path: "my-bookings-desktop",
        name: "my-bookings-desktop",
        component: () =>
          import("@/views/my-bookings-desktop/my-bookings-desktop.vue")
      }
    ],
    beforeEnter: (from, to, next) => {
      try {
        let roomId = "";
        let bookingId = "";
        let cabinetId = "";
        if (Bridge.isMiniApp()) {
          if (!location.href.includes("widget")) {
            Bridge.send("LifeMiniAppSupports", {
              method: "LifeMiniAppShowSplash"
            }).then((msg) => {
              if (msg.data.result) {
                Bridge.send("LifeMiniAppShowSplash", {}).then((msg) => {});
              } else {
                body.classList.add("body-loading");
              }
            });
          }
          // Bridge.send("LifeMiniAppSupports", {
          //   method: "LifeMiniAppShowSplash"
          // }).then(msg => {
          //   if (msg.data.result) {
          //     Bridge.send("LifeMiniAppShowSplash", {}).then(msg => {});
          //   } else {
          //     if (!location.href.includes("widget")) {
          //       body.classList.add("body-loading");
          //     }
          //   }
          // });
          Bridge.send("LifeMiniAppInit", {}).then((msg) => {
            console.log("payload из link:", msg.data.payload);
            if (msg.data.payload) {
              roomId = msg.data.payload.split("roomId:")[1];
              if (roomId) localStorage.setItem("room_id", roomId);
              bookingId = msg.data.payload.split("bookingId:")[1];
              if (bookingId) localStorage.setItem("booking_id", bookingId);
              cabinetId = msg.data.payload.split("cabinet=")[1];
              if (cabinetId) localStorage.setItem("cabinet_id", cabinetId);
            }
            Bridge.send("LifeMiniAppAuthCode", {})
              .then((msg) => {
                let waiting: boolean = true;
                infra.auth
                  .GetTokenByCode(msg.data.code)
                  .then(() => {
                    if (waiting) {
                      waiting = false;
                      if (body && body.classList) {
                        body.classList.remove("body-loading");
                      }
                      const accessToken = localStorage.getItem(
                        "booking_access_token"
                      );
                      if (accessToken) {
                        const data: {
                          user_id: string;
                          user_role: userRolesJWT;
                        } = JWT(accessToken);
                        if (data && data.user_id) {
                          if (data.user_role) {
                            store.commit("user/setUserRole", data.user_role);
                          }
                          store.commit("user/setUserId", data.user_id);
                        }
                      }
                      next();
                    }
                  })
                  .catch((err) => {
                    Bridge.send("LifeMiniAppNoAccess", {}).then((msg) => {});
                    console.log(err);
                    showError(
                      "Ошибка авторизации\nОбратитесь в техническую поддержку",
                      err
                    );
                    waiting = false;
                  });
                setTimeout(() => {
                  if (waiting) {
                    Bridge.send("LifeMiniAppNoAccess", {}).then((msg) => {});
                    showError(
                      "Ошибка авторизации\nОбратитесь в техническую поддержку"
                    );
                    waiting = false;
                  }
                }, 20000);
              })
              .catch((err) => {
                console.log("reset LocalStorage");
                resetStorages();
                Bridge.send("LifeMiniAppNoAccess", {}).then((msg) => {});
                showError(
                  "Ошибка авторизации\nОбратитесь в техническую поддержку",
                  err
                );
              });
          });
        } else {
          body.classList.add("body-loading");
          let waiting: boolean = true;
          const queryCode = from.query.code;
          const accessToken = localStorage.getItem("booking_access_token");
          if (queryCode && typeof queryCode === "string") {
            infra.auth
              .GetTokenByCode(queryCode)
              .then(() => {
                if (waiting) {
                  waiting = false;
                  if (body && body.classList) {
                    body.classList.remove("body-loading");
                  }
                  const redirectUrl = window.sessionStorage.getItem(
                    "booking_redirect_url"
                  );
                  if (redirectUrl) {
                    const { name, params, query, expires } =
                      JSON.parse(redirectUrl);
                    if (expires > Date.now()) {
                      router.push({
                        name: name || "",
                        params: params || "",
                        query: query || ""
                      });
                    } else {
                      next("/");
                    }
                    window.sessionStorage.removeItem("booking_redirect_url");
                  } else {
                    next("/");
                  }
                }
              })
              .catch((err) => {
                resetStorages();
                showError(
                  "Ошибка авторизации\nОбратитесь в техническую поддержку",
                  err
                );
              });
          } else if (accessToken) {
            const data: {
              user_id: string;
              user_role: userRolesJWT;
            } = JWT(accessToken);
            if (data && data.user_id) {
              if (data.user_role) {
                store.commit("user/setUserRole", data.user_role);
              }
              store.commit("user/setUserId", data.user_id);
            }
            const exp: string =
              localStorage.getItem("booking_access_token_expires_at") || "";
            if (exp && Number(exp) > Math.ceil(new Date().getTime() / 1000)) {
              if (body && body.classList) {
                body.classList.remove("body-loading");
              }
              next();
            } else {
              localStorage.removeItem("booking_access_token");
              localStorage.removeItem("booking_refresh_token");
              next("/");
            }
          } else {
            if (from && from && from.fullPath !== "/") {
              window.sessionStorage.removeItem("booking_redirect_url");
              window.sessionStorage.setItem(
                "booking_redirect_url",
                JSON.stringify({
                  ...from,
                  expires: Date.now() + 600000
                })
              );
            }
            infra.auth
              .GetRedirectUrl()
              .then((response) => {
                if (response) {
                  let redirectUrl = response;
                  if (process.env.NODE_ENV === "development") {
                    const urlFormatted = response.split("&");
                    const indexOfRedirect = urlFormatted.findIndex((item) =>
                      item.includes("redirect_uri=")
                    );
                    urlFormatted[indexOfRedirect] =
                      "redirect_uri=" + window.location.origin;
                    redirectUrl = urlFormatted.join("&");
                  }
                  window.location.replace(redirectUrl);
                } else {
                  showError(
                    "Ошибка авторизации\nОбратитесь в техническую поддержку"
                  );
                }
              })
              .catch((err) => {
                showError(
                  "Ошибка авторизации\nОбратитесь в техническую поддержку",
                  err
                );
              });
          }
        }
      } catch (e) {
        console.log(e);
        store.commit("setNetworkError", e);
      }
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from) => {
  if (to.meta.forMobile && !store.state.isMobile) {
    return {
      name: "timeline"
    };
  }
});

export default router;

function resetStorages(): void {
  localStorage.removeItem("booking_access_token");
  localStorage.removeItem("booking_refresh_token");
  localStorage.removeItem("booking_access_token_expires_at");
}

function showError(header: string, error?): void {
  const h2 = document.createElement("h2");
  const app = document.getElementById("app");
  if (app) app.style.display = "none";
  body.classList.remove("body-loading");
  h2.innerText = header;
  h2.classList.add("error-header");
  body.classList.add("error-mode");
  body.appendChild(h2);
  if (error && error.message) {
    const description = document.createElement("p");
    description.classList.add("error-description");
    description.innerText = error.message;
    body.appendChild(description);
  }
}
