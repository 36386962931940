import { reactive, toRefs, ref } from "vue";

const state = reactive({
  toasts: []
});

export default function () {
  const notify = (text, variant = "success") => {
    const toast = {
      text: text,
      duration: 3000,
      type: variant,
      creationTime: new Date().getTime()
    };
    state.toasts.push(toast);
  };

  const closeToast = (toast) => {
    const index = state.toasts.findIndex(
      (t) => t.creationTime === toast.creationTime
    );
    if (index > -1) {
      state.toasts.splice(index, 1);
    }
  };

  return { ...toRefs(state), notify, closeToast };
}
