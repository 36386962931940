import { createStore } from "vuex";

import filterModule from "./filterModule";
import booking from "./booking";
import building from "./building";
import cities from "./cities";
import rooms from "./rooms";
import coworkings from "./coworkings";
import user from "./user";
import myBooking from "./myBooking";
import equipments from "./equipments";
import maps from "./maps";
import chats from "./chats";
import seat from "./seat";
import locker from "./locker";
import lockerCabinet from "./lockerCabinet";

export default createStore({
  state: {
    isMobile: false,
    networkError: false,
    employeeCache: [
      {
        firstName: "",
        lastName: "",
        patronymicName: "",
        position: "",
        photoSmallFileName: "",
        photoFileName: "",
        portalCode: "",
        profileId: "",
        miniature: ""
      }
    ],
    splashIsActive: true,
    ws: {} as WebSocket,
    updateWSMarker: 0,
    updateWSMarkerRooms: 0,
    wsIsConnected: false
  },
  mutations: {
    setMobileVersion(state, payload) {
      state.isMobile = payload;
    },
    pushEmployeeCache(state, payload) {
      state.employeeCache.push(payload);
    },
    setSplashIsActive(state, payload) {
      state.splashIsActive = payload;
    },
    setNetworkError(state, payload) {
      state.networkError = payload;
    },
    setWS(state, payload) {
      state.ws = payload;
    },
    setUpdateWSMarker(state) {
      state.updateWSMarker++;
    },
    setUpdateWSMarkerRooms(state) {
      state.updateWSMarkerRooms++;
    },
    setWsIsConnected(state, payload) {
      state.wsIsConnected = payload;
    }
  },
  getters: {
    getSplashIsActive: (s: any) => s.splashIsActive,
    getNetworkError: (s: any) => s.networkError,
    getWS: (s: any) => s.ws,
    getUpdateWSMarker: (s: any) => s.updateWSMarker,
    getUpdateWSMarkerRooms: (s: any) => s.updateWSMarkerRooms,
    getWsIsConnected: (s: any) => s.wsIsConnected
  },
  modules: {
    filterModule,
    cities,
    booking,
    building,
    rooms,
    coworkings,
    user,
    myBooking,
    equipments,
    maps,
    chats,
    seat,
    locker,
    lockerCabinet
  }
});
