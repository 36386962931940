/* eslint-disable prefer-const */
/* eslint-disable no-console */
import Infrastructure from "@/infrastructure";
import Router from "../router";
import Store from "../store";
import { GrpcConfig } from "@/infrastructure/grpc-config";
import moment from "moment";
const infra = new Infrastructure();

let employeeCache = [
  {
    firstName: "",
    lastName: "",
    patronymicName: "",
    position: "",
    photoSmallFileName: "",
    photoFileName: "",
    portalCode: "",
    profileId: "",
    miniature: "",
    phoneMobile: "",
    phoneInternal: ""
  }
];
let intervalWS: any = 0;
let intervalHeartBeatWS: any = 0;

export enum userRolesJWT {
  REGULAR = 1, //Обычный пользователь
  ADMIN, //РПО
  SECURITY
}

export function getBinaryFromUrl(url: string): Promise<string> {
  return new Promise((resolve) => {
    if (!(url.match(/^https?:\/\//) || url.match(/^\/api/))) {
      resolve(url);
      return;
    }
    const request = new XMLHttpRequest();
    request.open("GET", window.VUE_APP_API_URL + url);
    request.setRequestHeader(
      "Authorization",
      "Bearer " + localStorage.getItem("booking_access_token")
    );
    request.send();
    request.onload = () => {
      if (request.status != 200) {
        resolve("");
      } else {
        resolve(request.response);
      }
    };
    request.onerror = () => {
      resolve("");
    };
  });
}

export function getEmployeeCache(code: string) {
  setTimeout(() => {
    if (!employeeCache?.some((o) => o.portalCode === code) && code) {
      infra.user
        .SelUsers({
          code: code
        })
        .then((response) => {
          if (!response.usersList.length) {
            employeeCache?.push({
              firstName: "",
              lastName: "Не найден",
              patronymicName: "",
              position: "",
              photoSmallFileName: "",
              photoFileName: "",
              portalCode: code,
              profileId: "",
              miniature: "",
              phoneMobile: "",
              phoneInternal: ""
            });
            Store.commit("pushEmployeeCache", {
              firstName: "",
              lastName: "Не найден",
              patronymicName: "",
              position: "",
              photoSmallFileName: "",
              photoFileName: "",
              portalCode: code,
              profileId: "",
              miniature: "",
              phoneMobile: "",
              phoneInternal: ""
            });
          } else if (!employeeCache?.some((o) => o.portalCode === code)) {
            employeeCache?.push({
              firstName: response.usersList[0].firstName,
              lastName: response.usersList[0].lastName,
              patronymicName: response.usersList[0].patronymicName,
              position: response.usersList[0].position,
              photoSmallFileName: response.usersList[0].photoSmallFileName,
              photoFileName: response.usersList[0].photoFileName,
              portalCode: response.usersList[0].portalCode,
              profileId: response.usersList[0].profileId,
              miniature: response.usersList[0].miniature,
              phoneMobile: response.usersList[0].phoneMobile,
              phoneInternal: response.usersList[0].phoneInternal
            });
            Store.commit("pushEmployeeCache", {
              firstName: response.usersList[0].firstName,
              lastName: response.usersList[0].lastName,
              patronymicName: response.usersList[0].patronymicName,
              position: response.usersList[0].position,
              photoSmallFileName: response.usersList[0].photoSmallFileName,
              photoFileName: response.usersList[0].photoFileName,
              portalCode: response.usersList[0].portalCode,
              profileId: response.usersList[0].profileId,
              miniature: response.usersList[0].miniature,
              phoneMobile: response.usersList[0].phoneMobile,
              phoneInternal: response.usersList[0].phoneInternal
            });
          }
        });
    }
  }, 0);
  return employeeCache;
}

export async function getUser(code: string) {
  const res = await infra.user.SelUsers({
    code: code
  });
  return res.usersList[0];
}

export function dateTimeToJson(time: string) {
  if (time && time.length > 20) {
    if (time.includes("T")) {
      return time;
    } else {
      return `${time.split(" ")[0]}T${time.split(" ")[1].split("+")[0]}Z`;
    }
  } else return "";
}

// дд.мм.гггг
export function formatDateFromObjectToString(date) {
  if (!date) return "";
  // return date.toLocaleDateString("en-GB");
  return date.toJSON().split("T")[0];
}

// 79999999999 -> +7 (999) 999-99-99
export function formatMobile(phoneNumber: string): string {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
  if (match) {
    return `+7 (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }
  return phoneNumber;
}

export function declensionSpace(number: number) {
  const cases = [2, 0, 1, 1, 1, 2];
  return (
    ["место", "места", "мест"][
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ] || ""
  );
}
export function declensionMembers(number: number) {
  const cases = [2, 0, 1, 1, 1, 2];
  return (
    ["участник", "участника", "участников"][
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ] || ""
  );
}
export function declensionOutMembers(number: number) {
  const cases = [2, 0, 1, 1, 1, 2];
  return (
    ["внешний", "внешнего", "внешних"][
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ] || ""
  );
}
export function declensionBooking(number: number) {
  const cases = [2, 0, 1, 1, 1, 2];
  return (
    ["встреча", "встречи", "встреч"][
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ] || ""
  );
}
export function checkVariables() {
  if (!window.VUE_APP_API_URL)
    console.log(`VUE_APP_API_URL='${window.VUE_APP_API_URL}'`);
  if (!window.VUE_APP_API_SOCKET)
    console.log(`VUE_APP_API_SOCKET='${window.VUE_APP_API_SOCKET}'`);
  if (!window.VUE_APP_YANDEX_METRIKA_ID)
    console.log(
      `VUE_APP_YANDEX_METRIKA_ID='${window.VUE_APP_YANDEX_METRIKA_ID}'`
    );
  if (!window.VUE_APP_LOCKER_ACTIVE)
    console.log(`VUE_APP_LOCKER_ACTIVE='${window.VUE_APP_LOCKER_ACTIVE}'`);
}

export function compareBookingsByDate(a, b) {
  const dateA = moment(a.from).toDate().getTime();
  const dateB = moment(b.from).toDate().getTime();
  return dateA - dateB;
}

export function translitToEn(word) {
  const converter = {
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    е: "e",
    ё: "e",
    ж: "zh",
    з: "z",
    и: "i",
    й: "y",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "h",
    ц: "c",
    ч: "ch",
    ш: "sh",
    щ: "sch",
    ь: "",
    ы: "y",
    ъ: "",
    э: "e",
    ю: "yu",
    я: "ya"
  };

  for (const [key, value] of Object.entries(converter)) {
    word = word.replaceAll(key, value);
  }

  return word || "";
}

export function translitToRu(word) {
  const converter = {
    sch: "щ",

    yo: "ё",
    zh: "ж",
    ch: "ч",
    sh: "ш",
    yu: "ю",
    ya: "я",
    kh: "х",

    a: "а",
    b: "б",
    v: "в",
    g: "г",
    d: "д",
    e: "е",
    z: "з",
    i: "и",
    y: "й",
    k: "к",
    l: "л",
    m: "м",
    n: "н",
    o: "о",
    p: "п",
    r: "р",
    s: "с",
    t: "т",
    u: "у",
    f: "ф",
    h: "х",
    c: "ц"
  };

  for (const [key, value] of Object.entries(converter)) {
    word = word.replaceAll(key, value);
  }

  return word || "";
}

export function WebSocketCreate() {
  if (!Store.getters["getNetworkError"]) {
    Store.commit("setWS", new WebSocket(GrpcConfig().socket));
    Store.getters["getWS"].onopen = function (e) {
      console.log("[open] WS Соединение установлено");
      Store.commit("setWsIsConnected", true);
    };
    Store.getters["getWS"].onclose = function (e) {
      console.log(
        "[close] WS Соединение закрыто c кодом:",
        e.code,
        "url:",
        GrpcConfig().socket
      );
      Store.commit("setWsIsConnected", false);
      WebSocketClose();
    };
    Store.getters["getWS"].onmessage = function (event) {
      console.log(`[message] Данные получены с сервера: ${event.data}`);
      if (event.data.includes("bookings")) Store.commit("setUpdateWSMarker");
      if (event.data.includes("rooms")) Store.commit("setUpdateWSMarkerRooms");
    };
  }
}

export function WebSocketAutoConnect() {
  // eslint-disable-next-line smells/no-setinterval
  intervalWS = setInterval(function () {
    if (!Store.getters["getWsIsConnected"] && !Store.getters["getWS"]) {
      // WebSocketClose();
      WebSocketCreate();
    }
  }, 1000);
}

export function WebSocketHeartBeat() {
  // eslint-disable-next-line smells/no-setinterval
  intervalHeartBeatWS = setInterval(function () {
    if (Store.getters["getWsIsConnected"]) {
      Store.getters["getWS"].send("heartBeat");
    }
  }, 15000);
}

export function WebSocketClose() {
  if (Store.getters["getWS"]) Store.getters["getWS"].close();
  Store.commit("setWS", "");
  intervalWS = 0;
  intervalHeartBeatWS = 0;
  clearInterval(intervalWS);
  clearInterval(intervalHeartBeatWS);
}

export function parentRoom(): { id: ""; roomsList: string[] } {
  const room = Store.getters["rooms/getParentRooms"][0] || {};
  return room;
}

export function childRoomAfterParent(rooms) {
  let sortedRooms = new Array();

  rooms.forEach((room) => {
    if (!sortedRooms.includes(room)) {
      sortedRooms.push(room);

      if (room.roomsList && room.roomsList.length > 0) {
        const childRooms = rooms.filter((childRoom) =>
          room.roomsList.includes(childRoom.id)
        );
        sortedRooms = sortedRooms.filter((item) => {
          return !childRooms.some((otherItem) => item.id === otherItem.id);
        });
        sortedRooms.push(...childRooms);
      }
    }
  });

  return sortedRooms;
}

export function childRoomBeforeParent(rooms) {
  let sortedRooms = new Array();

  rooms.forEach((room) => {
    if (!sortedRooms.includes(room)) {
      if (room.roomsList && room.roomsList.length > 0) {
        const childRooms = rooms.filter((childRoom) =>
          room.roomsList.includes(childRoom.id)
        );
        sortedRooms = sortedRooms.filter((item) => {
          return !childRooms.some((otherItem) => item.id === otherItem.id);
        });
        sortedRooms.push(...childRooms);
      }
      sortedRooms.push(room);
    }
  });

  return sortedRooms;
}
