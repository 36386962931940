import { deepClone } from "@/utils";
import { Coworking } from "../grpc/code-gen/coworking_pb";
import store from "@/store";
/* eslint-disable no-console */
import Infrastructure from "@/infrastructure";
import useNotification from "@/composables/useNotification";
const { notify } = useNotification();
const infra = new Infrastructure();

export default {
  namespaced: true,
  state: {
    requestCoworkings: [] as Coworking.AsObject[],
    requestAllCoworkings: [] as Coworking.AsObject[],
    filteredArray: [],
    checkFilteredArray: [],
    filterTimeFrom: "",
    filterTimeTill: "",
    currentCoworking: "",
    emptyCoworking: {
      id: "",
      building: "",
      floor: "",
      address: "",
      isvisible: true,
      securityemail: "",
      direction: "",
      city: "",
      totalseats: ""
    } as Coworking.AsObject
  },
  actions: {
    async loadCoworkingsList(
      s: any,
      payload: { city: string; building: string }
    ): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.coworking
          .GetCoworkingsList(payload.city, payload.building)
          .then((response) => {
            if (response) {
              s.commit(
                "addCoworkingsList",
                response.coworkingList.filter((r) => r.isvisible)
              );
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async loadAllCoworkingsList(s: any): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.coworking
          .GetAllCoworkingsList()
          .then((response) => {
            if (response) {
              s.commit("addAllCoworkingsList", response.coworkingList);
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
            store.commit("setNetworkError");
          });
      });
    },
    addCoworking(state: any, payload: Coworking.AsObject): void {
      infra.coworking
        .AddCoworking(payload)
        .then((response) => {
          console.log("add Coworking success", response);
          state.dispatch("loadAllCoworkingsList");

          // infra.booking.AddLostCopies();
        })
        .catch((error) => {
          console.log("add Coworking failed", error);
          store.commit("setNetworkError");
          notify(`Не удалось создать коворкинг. Попробуйте еще раз`, "error");
        });
    },
    delCoworking(state: any, payload: Coworking.AsObject): void {
      infra.coworking
        .DelCoworking(payload)
        .then((response) => {
          console.log("del Coworking success", response);
          state.dispatch("loadAllCoworkingsList");

        })
        .catch((error) => {
          console.log("del Coworking failed", error);
          store.commit("setNetworkError");
          notify(`Не удалось удалить коворкинг. Попробуйте еще раз`, "error");
        });
    },
    updCoworking(state: any, payload: Coworking.AsObject): void {
      infra.coworking
        .UpdCoworking(payload)
        .then((response) => {
          console.log("upd Coworking success", response);
          state.dispatch("loadAllCoworkingsList");

          payload.isvisible
            ? infra.booking.AddLostCopies()
            : infra.booking.DelLostCopies();
        })
        .catch((error) => {
          console.log("upd Coworking failed", error);
          store.commit("setNetworkError");
          notify(`Не удалось сохранить коворкинг. Попробуйте еще раз`, "error");
        });
    }
  } as any,
  mutations: {
    addCoworkingsList(state: any, arr) {
      state.requestCoworkings = arr;
    },
    addAllCoworkingsList(state: any, arr) {
      state.requestAllCoworkings = arr;
    },
    setFilteredArray(state: any, arr) {
      state.filteredArray = arr;
    },
    setCheckFilteredArray(state: any, arr) {
      state.checkFilteredArray = arr;
    },
    setFilterTimeFrom(state: any, payload: string) {
      state.filterTimeFrom = payload;
    },
    setFilterTimeTill(state: any, payload: string) {
      state.filterTimeTill = payload;
    },
    toggleCoworkingVisibility(state: any, payload: string) {
      const changedCoworking = state.requestAllCoworkings.find(
        (coworking) => coworking.id === payload
      );
      changedCoworking.isvisible = !changedCoworking.isvisible;
    },
    addCoworking(state: any, payload: any) {
      state.requestCoworkings.push(payload);
    },
    editCoworking(state: any, payload: any) {
      state.requestCoworkings = state.requestCoworkings.map((c) =>
        c.id === payload.id ? payload : c
      );
    },
    editCityInCoworkings(state: any, playload: any) {
      state.requestCoworkings = state.requestCoworkings.map((c) =>
        c.city === playload.city ? { ...c, city: playload.newCityName } : c
      );
    },
    editFloorInCoworkings(state: any, playload: any) {
      state.requestCoworkings = state.requestCoworkings.map((c) =>
        c.floor === playload.floor ? { ...c, floor: playload.newFloor } : c
      );
    }
  },
  getters: {
    getCoworkings(state: any) {
      return state.requestCoworkings;
    },
    getAllCoworkings(state: any) {
      return state.requestAllCoworkings;
    },
    getCoworkingsByCityId(state: any, city: string) {
      return state.requestCoworkings.filter((item) => {
        return item.city === city;
      });
    },
    getCoworkingsByBuildingId: (state: any) => (id: string) => {
      return state.requestAllCoworkings.filter((item) => {
        return item.building === id;
      });
    },
    getCoworkingById: (state: any) => (id: string) => {
      const obj = state.requestAllCoworkings.filter((coworking) => {
        return coworking.id === id;
      })[0];
      return obj || state.emptyCoworking;
    },
    currentCoworking: (s: any) =>
      s.currentCoworking || s.requestCoworkings[0]?.name,
    getParentCoworkings: (state: any) =>
      state.requestCoworkings.filter(
        (coworking) => coworking?.coworkingList?.length
      ),
    getFilteredArray: (state: any) => state.filteredArray,
    getCoworkingFloor: (state: any) => (coworkingId: string) => {
      const coworking = state.requestAllCoworkings.find(
        (coworking) => coworking.id === coworkingId
      );
      return coworking ? coworking.floor : "-";
    },
    getCoworkingAddress: (state: any) => (coworkingId: string) => {
      const coworking = state.requestAllCoworkings.find(
        (coworking) => coworking.id === coworkingId
      );
      return coworking ? coworking.address : "Не найден";
    }
  }
};
