/* eslint-disable no-console */
import { createApp } from "vue";
import App from "./app.vue";
import router from "./router";
import store from "./store";
import eruda from "eruda";

import VueYandexMetrika from "vue3-yandex-metrika";
import LifeBridge from "@tnlife/tn-bridge";
import TNLifeUIKit from "@life_uikit/uikit";

import "@/assets/styles/reset.scss";
import "@/assets/styles/global.scss";
import "@/assets/styles/fonts.scss";
import "@/assets/styles/kit-upd.scss";
import { checkVariables } from "./common/common";
import "@life_uikit/uikit/variables.css";

const vueApp = createApp(App);

if (process.env.NODE_ENV === "development" && LifeBridge.isWebView) {
  eruda.init();
}
// eruda.init();
checkVariables();

if (window.VUE_APP_YANDEX_METRIKA_ID) {
  vueApp.use(VueYandexMetrika, {
    id: window.VUE_APP_YANDEX_METRIKA_ID,
    router: router,
    env: "production",
    options: {
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: true
    }
  });
}

vueApp.use(store).use(router).use(TNLifeUIKit).mount("#app");
