import store from "@/store";
/* eslint-disable no-console */
import IBooking from "@/models/booking";
import IParticipant from "@/models/participant";
import IRoom from "@/models/room";
import Infrastructure from "@/infrastructure";
import { Booking } from "@/grpc/code-gen/booking_pb";
import Router from "../router";
import useNotification from "@/composables/useNotification";
const infra = new Infrastructure();
const { notify } = useNotification();
import { genTextNotify } from "@/utils/genTextNotify";

export default {
  namespaced: true,
  state: {
    TimeSelectFrom: "00:00",
    TimeSelectTill: "00:00",
    scrollTo: 0,
    activeQrTimer: {
      id: "",
      timer: null
    },
    timerInterval: null,
    timeSelectChangeMarker: 0,
    submitEdit: 0,
    timeIsBusy: false,
    selectedDate: new Date(),
    selectedDateTimeline: new Date(),
    selectedDateBO: new Date(),
    idEditBooking: "",
    idRepeatBooking: "",
    bookingNotChange: true,
    selectedRoom: {
      name: "",
      id: "",
      floor: 0,
      space: 0,
      img: "undefined",
      equipment: [],
      bookings: []
    } as unknown as IRoom,
    selectedBooking: {} as Booking.AsObject,
    selectedBookingExp: false,
    bookings: [] as Booking.AsObject[],
    bookingsFutureListAll: [] as Booking.AsObject[],
    bookingsExpiredListAll: [] as Booking.AsObject[],
    mapIsVisible: false,
    selectedSeat: {
      id: "",
      number: 0,
      coworking: "",
      isavailable: false,
      booking: []
    },
    member: {
      name: "",
      email: "",
      car: ""
    }
  },
  actions: {
    async loadBookingsList(s: any, date: string): Promise<void> {
      try {
        const response = await infra.booking.GetBookingsList(date);
        if (date === new Date().toJSON().split("T")[0]) {
          s.commit("addBookingsList", response.bookingList);
        } else {
          s.commit("pushBookingsList", response.bookingList);
        }
      } catch (error) {
        console.log(error);
        notify("Ошибка при загрузке списка бронирований", "error");
      }
    },
    async loadBookingsByDate(s: any, date: string): Promise<void> {
      try {
        const response = await infra.booking.GetBookingsList(date);
        s.commit("addBookingsList", response.bookingList);
      } catch (error) {
        console.log(error);
        notify("Ошибка при загрузке списка бронирований", "error");
      }
    },
    async loadBookingFutureListAll(
      s: any,
      payload: {
        limit?: number;
        offset?: number;
        search?: string;
        date?: string;
        city?: string;
        building?: string;
        room?: string;
        onlywithout?: boolean;
      }
    ): Promise<void> {
      try {
        const response = await infra.booking.GetBookingsFutureListAll(payload);
        if (payload.offset == 0) {
          s.commit("addBookingsFutureListAll", response.bookingList);
        } else {
          s.commit("pushBookingsFutureListAll", response.bookingList);
        }
      } catch (error) {
        console.log(error);
        notify("Ошибка при загрузке списка будущих бронирований", "error");
      }
    },
    async loadBookingExpiredListAll(
      s: any,
      payload: {
        limit?: number;
        offset?: number;
        search?: string;
        date?: string;
        city?: string;
        building?: string;
        room?: string;
        onlywithout?: boolean;
      }
    ): Promise<void> {
      try {
        const response = await infra.booking.GetBookingsExpiredListAll(payload);
        if (payload.offset == 0) {
          s.commit("addBookingsExpiredListAll", response.bookingList);
        } else {
          s.commit("pushBookingsExpiredListAll", response.bookingList);
        }
      } catch (error) {
        console.log(error);
        notify("Ошибка при загрузке списка прошедших бронирований", "error");
      }
    },
    async addBooking(state: any, payload: Booking.AsObject): Promise<void> {
      try {
        const response = await infra.booking.AddBooking(payload);
        console.log("add booking success  ", response);
      } catch (error) {
        console.log("add booking failed", error);
        throw error;
      }
    },
    async updBooking(state: any, payload: Booking.AsObject): Promise<void> {
      try {
        const response = await infra.booking.UpdBooking(payload);
        console.log("upd booking success  ", response);
      } catch (error) {
        console.log("upd booking failed", error);
        throw error;
      }
    },
    async cancelBooking(state: any, payload: Booking.AsObject): Promise<void> {
      try {
        const response = await infra.booking.UpdCancelBooking(payload);

        console.log("cancel booking success  ", response);
        state.commit("deleteBooking", payload.id);
      } catch (error) {
        notify(
          `Не удалось отменить бронь ${genTextNotify(
            payload
          )}. Попробуйте еще раз`,
          "error"
        );
        console.log("cancel booking failed", error);
        throw error;
      }
    },
    async selIdRoom(s: any, id: string): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.room
          .SelIdRoom(id)
          .then((response) => {
            if (response) {
              s.commit("setRoom", response.roomList[0]);
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async selIdBooking(s: any, id: string): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.booking
          .SelIdBooking(id)
          .then((response) => {
            if (response) {
              s.commit("setSelectedBooking", response.bookingList[0]);
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async addLostCopies(): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.booking
          .AddLostCopies()
          .then((response) => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async delLostCopies(): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.booking
          .DelLostCopies()
          .then((response) => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  } as any,
  mutations: {
    setActiveQrTimer(state: any, id: string) {
      clearInterval(state.timerInterval);

      // Устанавливаем время окончания (2 минуты от текущего времени)
      const endTime = new Date().getTime() + 120 * 1000;

      state.timerInterval = setInterval(() => {
        const now = new Date().getTime();
        const timeLeft = endTime - now;

        if (timeLeft <= 0) {
          clearInterval(state.timerInterval);
          state.activeQrTimer = {
            id: "",
            timer: null
          };
          return;
        }

        const minutes = Math.floor(timeLeft / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        state.activeQrTimer = {
          id: id,
          timer:
            minutes > 0
              ? `${minutes} мин. ${seconds.toString().padStart(2, "0")} сек.`
              : `${seconds.toString().padStart(2, "0")} сек.`
        };
      }, 1000);
    },
    setInactiveQrTimer(state: any) {
      clearInterval(state.timerInterval);
      state.activeQrTimer = {
        id: "",
        timer: null
      };
    },
    pushBookingsList(state: any, arr) {
      state.bookings = [...state.bookings, ...arr];
    },
    addBookingsList(state: any, arr) {
      state.bookings = arr;
    },
    pushBookingsFutureListAll(state: any, arr) {
      state.bookingsFutureListAll = [...state.bookingsFutureListAll, ...arr];
    },
    addBookingsFutureListAll(state: any, arr) {
      state.bookingsFutureListAll = arr;
    },
    pushBookingsExpiredListAll(state: any, arr) {
      state.bookingsExpiredListAll = [...state.bookingsExpiredListAll, ...arr];
    },
    addBookingsExpiredListAll(state: any, arr) {
      state.bookingsExpiredListAll = arr;
    },
    addTimeSelectFrom(state: any, from: string) {
      state.TimeSelectFrom = from;
    },
    addTimeSelectTill(state: any, till: string) {
      state.TimeSelectTill = till;
    },
    addTimeSelectChangeMarker(state: any) {
      state.timeSelectChangeMarker += 1;
    },
    pushBooking: (state: any, booking) => {
      state.bookings.reduce((acc, item) => {
        if (item.id === booking.id) {
          return [...acc, booking];
        }
        return [...acc, item];
      }, []);
      if (state.idEditBooking == "") {
        state.bookings.push({ ...booking });
      }
    },
    setDate(state: any, date: object) {
      state.selectedDate = date;
    },
    setDateTimeline(state: any, date: object) {
      state.selectedDateTimeline = date;
    },
    setDateBO(state: any, date: object) {
      state.selectedDateBO = date;
    },
    setRoom(state: any, room: object) {
      state.selectedRoom = room;
    },
    setTimeIsBusy(state: any, value) {
      state.timeIsBusy = value;
    },
    setSelectedBooking(state: any, booking: object) {
      state.selectedBooking = booking;
    },
    setSelectedBookingExp(state: any, payload: Boolean) {
      state.selectedBookingExp = payload;
    },
    addIdEditBooking(state: any, id: string) {
      state.idEditBooking = id;
    },
    addIdRepeatBooking(state: any, id: string) {
      state.idRepeatBooking = id;
    },
    addBookingNotChange(state: any, payload: boolean) {
      state.bookingNotChange = payload;
    },
    cancelBooking(state: any, id: string) {
      if (state.bookings.find((b) => b.id === id)) {
        state.bookings.find((b) => b.id === id).canceled = true;
      }
    },
    deleteBooking(state: any, id: string) {
      state.bookings = state.bookings.filter((b) => b.id !== id);
    },
    submitEdit(state: any) {
      state.submitEdit++;
    },
    setScrollTo(state: any, payload) {
      state.scrollTo = payload;
      setTimeout(() => {
        state.scrollTo = 0;
      }, 5000);
    },
    setMapIsVisible(state: any, payload: boolean) {
      state.mapIsVisible = payload;
    },
    setSeat(state: any, seat: object) {
      state.selectedSeat = seat;
    },
    setMemberData(state: any, member: object) {
      state.member = member;
    }
  },
  getters: {
    from: (s: any) => s.TimeSelectFrom,
    till: (s: any) => s.TimeSelectTill,
    timeSelectChangeMarker: (s: any) => s.timeSelectChangeMarker,
    submitEdit: (s: any) => s.submitEdit,
    getBookings: (s: any) => s.bookings,
    getBookingsFutureListAll: (s: any) => s.bookingsFutureListAll,
    getBookingsExpiredListAll: (s: any) => s.bookingsExpiredListAll,
    selectedDate: (s: any) => s.selectedDate,
    selectedDateBO: (s: any) => s.selectedDateBO,
    selectedDateTimeline: (s: any) => s.selectedDateTimeline,
    selectedRoom: (s: any) => s.selectedRoom,
    selectedBooking: (s: any) => s.selectedBooking,
    selectedBookingExp: (s: any) => s.selectedBookingExp,
    idEditBooking: (s: any) => s.idEditBooking,
    idRepeatBooking: (s: any) => s.idRepeatBooking,
    timeIsBusy: (s: any) => s.timeIsBusy,
    bookingNotChange: (s: any) => s.bookingNotChange,
    getBookingById: (s: any) => (id: string) => {
      return s.bookings.find((booking) => booking.id === id) || { id: 0 };
    },
    getBookingBySeat: (s: any) => (seatId: string) => {
      return s.bookings.find(
        (booking) => booking.seat === seatId && booking.canceled === false
      );
    },
    getBookingByLocker: (s: any) => (lockerId: string) => {
      return s.bookings.find(
        (booking) => booking.locker === lockerId && booking.canceled === false
      );
      // return {
      //   canceled: false,
      //   copy: "",
      //   date: "2024-11-01",
      //   desc: "",
      //   from: "2024-11-01T05:00:00.000Z",
      //   host: "555555",
      //   id: "a1673fda-0cc9-4529-9a4c-9caef23eefd2",
      //   img: "",
      //   lastopen: "",
      //   locker: "c076ef58-b0fe-43e3-8968-b54f1c48a437",
      //   membersList: [],
      //   notification: 0,
      //   outList: [],
      //   regular: false,
      //   renewals: "",
      //   room: "",
      //   seat: "",
      //   till: "",
      //   type: "locker"
      // };
    },
    getBookingsByDateAndRooms: (s: any) => (dateJson: string, room) => {
      const date = dateJson.split("T")[0];
      return s.bookings.filter((booking) => {
        return (
          booking.room === room?.id &&
          date === booking.from.split(" ")[0] &&
          booking.canceled === false
        );
      });
    },
    getScrollTo: (s: any) => s.scrollTo,
    getMapIsVisible: (s: any) => s.mapIsVisible,
    selectedSeat: (s: any) => s.selectedSeat,
    setMember: (s: any) => s.member,
    activeQrTimer: (s: any) => s.activeQrTimer
  }
};
