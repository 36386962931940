import { deepClone } from "@/utils";
import store from "@/store";
/* eslint-disable no-console */
import Infrastructure from "@/infrastructure";
import { Cabinet } from "@/grpc/code-gen/cabinet_pb";
import useNotification from "@/composables/useNotification";
import {
  LockerServiceCabinet,
  LockerServiceCabinetsRequest
} from "@/grpc/code-gen/db_pb";
const { notify } = useNotification();
const infra = new Infrastructure();

export default {
  namespaced: true,
  state: {
    requestLockerCabinets: [] as Cabinet.AsObject[],
    requestAllLockerCabinets: [] as Cabinet.AsObject[],
    merusoftLockerCabinets: [] as LockerServiceCabinet.AsObject[],
    filteredArray: [],
    checkFilteredArray: [],
    filterTimeFrom: "",
    filterTimeTill: "",
    currentLockerCabinet: "",
    selectedLockerCabinet: {} as Cabinet.AsObject,
    emptyLockerCabinet: {
      id: "",
      building: "",
      floor: "",
      name: "",
      smartofficeid: 0,
      map: "",
      address: "",
      isavailable: true,
      isvisible: true,
      direction: "",
      city: ""
    } as Cabinet.AsObject
  },
  actions: {
    async loadLockerCabinetList(
      s: any,
      payload: { city: string; building: string }
    ): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.lockerCabinet
          .GetLockerCabinetList(payload.city, payload.building)
          .then((response) => {
            if (response) {
              s.commit(
                "addLockerCabinetList",
                response.cabinetList.filter((r) => r.isvisible)
              );
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async loadMerusoftLockerCabinetList(
      s: any,
      payload: LockerServiceCabinetsRequest.AsObject
    ): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.lockerCabinet
          .GetMerusoftLockerCabinetList(payload)
          .then((response) => {
            if (response) {
              s.commit(
                "addMerusoftLockerCabinetList",
                response.lockerservicecabinetList
              );
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async loadAllLockerCabinetList(s: any): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.lockerCabinet
          .GetAllLockerCabinetList()
          .then((response) => {
            if (response) {
              s.commit("addAllLockerCabinetList", response.cabinetList);
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            notify("Проблема со связью с СУОП. Попробуйте позже", "error");
            reject(error);
            store.commit("setNetworkError");
          });
      });
    },
    addLockerCabinet(state: any, payload: Cabinet.AsObject): void {
      infra.lockerCabinet
        .AddLockerCabinet(payload)
        .then((response) => {
          console.log("add LockerCabinet success", response);
          state.dispatch("loadAllLockerCabinetList");
        })
        .catch((error) => {
          console.log("add LockerCabinet failed", error);
          store.commit("setNetworkError");
          notify(`Не удалось создать локер ${payload.name}`, "error");
        });
    },
    delLockerCabinet(state: any, payload: Cabinet.AsObject): void {
      infra.lockerCabinet
        .DelLockerCabinet(payload)
        .then((response) => {
          console.log("del LockerCabinet success", response);
          state.dispatch("loadAllLockerCabinetList");
        })
        .catch((error) => {
          console.log("del Coworking failed", error);
          store.commit("setNetworkError");
          notify(
            `Не удалось удалить локер ${payload.name}. Попробуйте еще раз`,
            "error"
          );
        });
    },
    updLockerCabinet(state: any, payload: Cabinet.AsObject): void {
      infra.lockerCabinet
        .UpdLockerCabinet(payload)
        .then((response) => {
          console.log("upd LockerCabinet success", response);
          state.dispatch("loadAllLockerCabinetList");
          payload.isvisible
            ? infra.booking.AddLostCopies()
            : infra.booking.DelLostCopies();

        })
        .catch((error) => {
          console.log("upd LockerCabinet failed", error);
          store.commit("setNetworkError");
          notify(
            `Не удалось сохранить локер ${payload.name}. Попробуйте еще раз`,
            "error"
          );
        });
    }
  } as any,
  mutations: {
    addLockerCabinetList(state: any, arr) {
      state.requestLockerCabinets = arr;
    },
    addMerusoftLockerCabinetList(state: any, arr) {
      state.merusoftLockerCabinets = arr;
    },
    setSelectedLockerCabinet(state: any, cab) {
      state.selectedLockerCabinet = cab;
    },
    addAllLockerCabinetList(state: any, arr) {
      state.requestAllLockerCabinets = arr;
    },
    setFilteredArray(state: any, arr) {
      state.filteredArray = arr;
    },
    setCheckFilteredArray(state: any, arr) {
      state.checkFilteredArray = arr;
    },
    setFilterTimeFrom(state: any, payload: string) {
      state.filterTimeFrom = payload;
    },
    setFilterTimeTill(state: any, payload: string) {
      state.filterTimeTill = payload;
    },
    toggleLockerCabinetVisibility(state: any, payload: string) {
      const changedLockerCabinet = state.requestAllLockerCabinets.find(
        (lockerCabinet) => lockerCabinet.id === payload
      );
      changedLockerCabinet.isvisible = !changedLockerCabinet.isvisible;
    },
    addLockerCabinet(state: any, payload: any) {
      state.requestLockerCabinets.push(payload);
    },
    editLockerCabinet(state: any, payload: any) {
      state.requestLockerCabinets = state.requestLockerCabinets.map((c) =>
        c.id === payload.id ? payload : c
      );
    },
    editCityInLockerCabinets(state: any, playload: any) {
      state.requestLockerCabinets = state.requestLockerCabinets.map((c) =>
        c.city === playload.city ? { ...c, city: playload.newCityName } : c
      );
    },
    editFloorInLockerCabinets(state: any, playload: any) {
      state.requestLockerCabinets = state.requestLockerCabinets.map((c) =>
        c.floor === playload.floor ? { ...c, floor: playload.newFloor } : c
      );
    }
  },
  getters: {
    getLockerCabinets(state: any) {
      return state.requestLockerCabinets;
    },
    getMerusoftLockerCabinets(state: any) {
      return state.merusoftLockerCabinets;
    },
    getSelectedLockerCabinet(state: any) {
      return state.selectedLockerCabinet;
    },
    getAllLockerCabinets(state: any) {
      return state.requestAllLockerCabinets;
    },
    getLockerCabinetsByCityId(state: any, city: string) {
      return state.requestLockerCabinets.filter((item) => {
        return item.city === city;
      });
    },
    getLockerCabinetsByBuildingId: (state: any) => (id: string) => {
      return state.requestAllLockerCabinets.filter((item) => {
        return item.building === id;
      });
    },
    getLockerCabinetById: (state: any) => (id: string) => {
      const obj = state.requestAllLockerCabinets.filter((lockerCabinet) => {
        return lockerCabinet.id === id;
      })[0];
      return obj || state.emptyLockerCabinet;
    },
    currentLockerCabinet: (s: any) =>
      s.currentLockerCabinet || s.requestLockerCabinets[0]?.name,
    getParentLockerCabinets: (state: any) =>
      state.requestLockerCabinets.filter(
        (lockerCabinet) => lockerCabinet?.lockerCabinetList?.length
      ),
    getFilteredArray: (state: any) => state.filteredArray,
    getLockerCabinetFloor: (state: any) => (lockerCabinetId: string) => {
      const lockerCabinet = state.requestAllLockerCabinets.find(
        (lockerCabinet) => lockerCabinet.id === lockerCabinetId
      );
      return lockerCabinet ? lockerCabinet.floor : "-";
    },
    getLockerCabinetAddress: (state: any) => (lockerCabinetId: string) => {
      const lockerCabinet = state.requestAllLockerCabinets.find(
        (lockerCabinet) => lockerCabinet.id === lockerCabinetId
      );
      return lockerCabinet ? lockerCabinet.address : "Не найден";
    }
  }
};
