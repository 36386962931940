import { deepClone } from "@/utils";
import { Room } from "./../grpc/code-gen/room_pb.d";
import store from "@/store";
/* eslint-disable no-console */
import Infrastructure from "@/infrastructure";
import Router from "../router";
import { allRooms, rooms } from "@/infrastructure/mock";
import { childRoomAfterParent } from "@/common/common";
import useNotification from "@/composables/useNotification";
const { notify } = useNotification();
const infra = new Infrastructure();

export default {
  namespaced: true,
  state: {
    requestRooms: [] as Room.AsObject[],
    requestAllRooms: [] as Room.AsObject[],
    filteredArray: [],
    checkFilteredArray: [],
    filterTimeFrom: "",
    filterTimeTill: "",
    currentRoom: "",
    parentRoomIsBusy: false,
    emptyRoom: {
      id: "",
      name: "Не найдена",
      space: "",
      equipmentList: [],
      building: "",
      floor: "",
      securityemail: "",
      address: "",
      direction: "",
      isvisible: true,
      img: "",
      city: "",
      avatar: "",
      roomsList: [],
      serviceemail: "",
      chatid: ""
    } as Room.AsObject
  },
  actions: {
    async loadRoomsList(
      s: any,
      payload: { city: string; building: string }
    ): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.room
          .GetRoomsList(payload.city, payload.building)
          .then((response) => {
            if (response) {
              s.commit(
                "addRoomsList",
                response.roomList.filter((r) => r.isvisible)
              );
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async loadAllRoomsList(s: any): Promise<void> {
      return await new Promise((resolve, reject) => {
        infra.room
          .GetAllRoomsList()
          .then((response) => {
            if (response) {
              s.commit("addAllRoomsList", response.roomList);
              resolve();
            } else {
              reject();
            }
          })
          .catch((error) => {
            reject(error);
            store.commit("setNetworkError");
          });
      });
    },
    addRoom(state: any, payload: Room.AsObject): void {
      infra.room
        .AddRoom(payload)
        .then((response) => {
          console.log("add room success  ", response);
          state.dispatch("loadAllRoomsList");
          infra.booking.AddLostCopies();

        })
        .catch((error) => {
          console.log("add room failed", error);
          store.commit("setNetworkError");
          notify(
            `Не удалось создать переговорную ${payload.name}. Попробуйте еще раз`,
            "error"
          );
        });
    },
    updRoom(state: any, payload: Room.AsObject): void {
      infra.room
        .UpdRoom(payload)
        .then((response) => {
          console.log("upd room success  ", response);
          state.dispatch("loadAllRoomsList");
          payload.isvisible
            ? infra.booking.AddLostCopies()
            : infra.booking.DelLostCopies();

        })
        .catch((error) => {
          console.log("upd room failed", error);
          store.commit("setNetworkError");
          notify(
            `Не удалось сохранить переговорную ${payload.name}. Попробуйте еще раз`,
            "error"
          );
        });
    },
    delRoom(state: any, payload: Room.AsObject): void {
      infra.room
        .DelRoom(payload)
        .then((response) => {
          console.log("del room success  ", response);
          infra.booking.DelLostCopies();
          state.dispatch("loadAllRoomsList");

        })
        .catch((error) => {
          console.log("del room failed", error);
          store.commit("setNetworkError");
          notify(
            `Не удалось удалить переговорную ${payload.name}. Попробуйте еще раз`,
            "error"
          );
        });
    }
  } as any,
  mutations: {
    addRoomsList(state: any, arr) {
      state.requestRooms = arr;
    },
    addAllRoomsList(state: any, arr) {
      state.requestAllRooms = arr;
    },
    setFilteredArray(state: any, arr) {
      state.filteredArray = arr;
    },
    setCheckFilteredArray(state: any, arr) {
      state.checkFilteredArray = arr;
    },
    setFilterTimeFrom(state: any, payload: string) {
      state.filterTimeFrom = payload;
    },
    setFilterTimeTill(state: any, payload: string) {
      state.filterTimeTill = payload;
    },
    setRoomName(state: any, payload: string) {
      state.currentRoom = payload;
    },
    toggleRoomVisibility(state: any, payload: string) {
      const changedRoom = state.requestAllRooms.find(
        (room) => room.id === payload
      );
      changedRoom.isvisible = !changedRoom.isvisible;
    },
    deleteRoom(state: any, payload: string) {
      state.requestRooms = state.requestRooms.filter(
        (room) => room.id !== payload
      );
    },
    addRoom(state: any, payload: any) {
      state.requestRooms.push(payload);
    },
    editRoom(state: any, payload: any) {
      state.requestRooms = state.requestRooms.map((r) =>
        r.id === payload.id ? payload : r
      );
    },
    editCityInRooms(state: any, playload: any) {
      state.requestRooms = state.requestRooms.map((r) =>
        r.city === playload.city ? { ...r, city: playload.newCityName } : r
      );
    },
    editFloorInRooms(state: any, playload: any) {
      state.requestRooms = state.requestRooms.map((r) =>
        r.floor === playload.floor ? { ...r, floor: playload.newFloor } : r
      );
    },
    setParentRoomIsBusy(state: any, payload: string) {
      state.parentRoomIsBusy = payload;
    }
  },
  getters: {
    getRooms(state: any) {
      return state.requestRooms;
    },
    getAllRooms(state: any) {
      return state.requestAllRooms;
    },
    getRoomsByCityId(state: any, city: string) {
      return state.requestRooms.filter((item) => {
        return item.city === city;
      });
    },
    getRoomsByBuildingId(state: any, id: string) {
      return state.requestRooms.filter((item) => {
        return item.building === id;
      });
    },
    getfilteredRooms(state: any) {
      let data = state.requestRooms.sort((a, b) => {
        if (a.floor !== b.floor) {
          return a.floor - b.floor;
        } else return b.space - a.space;
      });
      if (state.filteredArray.length) {
        data = data.filter((room) =>
          state.filteredArray.every((equipment) => {
            const bool = room.equipmentList.find((e) => {
              return e == equipment;
            });
            return bool ? true : false;
          })
        );
      }
      //дополнительная сортировка. Если есть родительская комната, то она идет перед дочерними
      data = childRoomAfterParent(deepClone(data));

      //сортировка по времени
      // if (state.filterTimeFrom !== "") {
      //   data = data.map(item => {
      //     const arrBookings = findIntersection(
      //       item.bookings,
      //       state.filterTimeFrom,
      //       state.filterTimeTill
      //     );
      //     item.overlap = arrBookings.length;
      //     return item;
      //   });
      //   console.log(data);
      //   data.sort((a, b) => {
      //     return a.bookings.overlap - b.bookings.overlap;
      //   });
      // }

      return JSON.parse(JSON.stringify(data));
    },
    getfilteredAllRooms(state: any) {
      // let data = state.requestRooms.sort((a, b) => a.floor - b.floor);
      let data = state.requestAllRooms.sort((a, b) => {
        if (a.floor !== b.floor) {
          return a.floor - b.floor;
        } else return b.space - a.space;
      });

      if (state.filteredArray.length) {
        data = data.filter((room) =>
          state.filteredArray.every((equipment) => {
            const bool = room.equipmentList.find((e) => {
              return e == equipment;
            });
            return bool ? true : false;
          })
        );
      }

      return JSON.parse(JSON.stringify(data));
    },
    getCheckFilteredRooms(state: any) {
      let data = state.requestRooms.sort((a, b) => a.floor - b.floor);
      if (state.checkFilteredArray.length) {
        data = data.filter((room) =>
          state.checkFilteredArray.every((equipment) => {
            const bool = room.equipmentList.find((e) => {
              return e == equipment;
            });
            return bool ? true : false;
          })
        );
      }
      return JSON.parse(JSON.stringify(data));
    },
    getRoomByName: (state: any) => (name: string) => {
      return state.requestAllRooms.find((room) => room.name === name);
    },
    getRoomById: (state: any) => (id: string) => {
      const obj = state.requestAllRooms.filter((room) => {
        return room.id === id;
      })[0];
      return obj || state.emptyRoom;
    },
    getActiveRoomById: (state: any) => (id: string) => {
      const obj = state.requestAllRooms.filter((room) => {
        return room.id === id && room.isvisible === true;
      })[0];
      return obj;
    },
    getFilterTimeFrom: (state: any) => state.filterTimeFrom,
    getFilterTimeTill: (state: any) => state.filterTimeTill,
    currentRoom: (s: any) =>
      s.currentRoom ? s.currentRoom : s.requestRooms[0]?.name,
    getParentRooms: (state: any) =>
      state.requestRooms.filter((room) => room?.roomsList?.length),
    getParentRoomIsBusy: (state: any) => state.parentRoomIsBusy,
    getFilteredArray: (state: any) => state.filteredArray
  }
};
