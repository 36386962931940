import { Booking } from "@/grpc/code-gen/booking_pb";
import store from "@/store";
function genTextNotify(booking: Booking.AsObject) {
  if (booking.type === "room") {
    const roomName = getRoomName(booking.room);
    return `переговорной ${roomName}`;
  } else if (booking.type === "coworking") {
    const seatNumber = getSeatNumber(booking.seat);
    return `места ${seatNumber}`;
  } else if (booking.type === "locker") {
    const lockerName = getLockerName(booking.locker);
    return `ячейки ${lockerName}`;
  }
}
function getSeatNumber(id: string): string {
  const result = store.getters["seat/getSeatById"](id)?.number;

  return result || "";
}
function getLockerName(id: string): string {
  const result = store.getters["locker/getLockerById"](id)?.name;
  return result || "";
}
function getRoomName(code: string): string {
  const result = store.getters["rooms/getRoomById"](code)?.name;

  return result || "";
}

export { genTextNotify };
