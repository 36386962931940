import { Seat } from "./../grpc/code-gen/seat_pb.d";
import { BookingResponse } from "./../grpc/code-gen/booking_pb.d";
import { Building, BuildingResponse } from "@/grpc/code-gen/building_pb";
import { City, CityResponse } from "@/grpc/code-gen/city_pb";
import { Room, RoomCheckedList, RoomResponse } from "@/grpc/code-gen/room_pb";
import { Coworking, CoworkingResponse } from "@/grpc/code-gen/coworking_pb";
import { Booking, Out } from "@/grpc/code-gen/booking_pb";
import { EquipmentResponse } from "@/grpc/code-gen/equipment_pb";
import {
  EmployeeListResponse,
  EmployeeResponse
} from "@/grpc/code-gen/employee_pb";
import {
  CheckUserRoleResponse,
  Users,
  UsersResponse
} from "@/grpc/code-gen/users_pb";
import { LinkResponse } from "@/grpc/code-gen/s3_pb";
import { Map, MapResponse } from "@/grpc/code-gen/maps_pb";
import { ChatResponse } from "@/grpc/code-gen/telegram_pb";
import { SeatResponse } from "@/grpc/code-gen/seat_pb";
import { Cabinet, CabinetResponse } from "@/grpc/code-gen/cabinet_pb";
import { Locker, LockerResponse } from "@/grpc/code-gen/locker_pb";
import {
  AppPayloadRequest,
  AppPayloadResponse,
  LockerServiceBuildingsResponse,
  LockerServiceCabinetsRequest,
  LockerServiceCabinetsResponse,
  LockerServiceLockersResponse
} from "@/grpc/code-gen/db_pb";

export interface iInfrastructure {
  auth: {
    GetTokenByCode(code: string): Promise<boolean>;
    RefreshToken(): Promise<boolean>;
    GetRedirectUrl(): Promise<string>;
  };
  user: {
    CheckUserRole(
      code: string,
      role: string
    ): Promise<CheckUserRoleResponse.AsObject>;
    GetUsersList(): Promise<UsersResponse.AsObject>;
    SelUsers(payload: { code: string }): Promise<UsersResponse.AsObject>;
  };
  location: {
    GetCitiesList(): Promise<CityResponse.AsObject>;
    AddCity(payload: {
      id: string;
      name: string;
      utc: string;
    }): Promise<City | Object>;
    UpdCity(payload: {
      id: string;
      name: string;
      utc: string;
    }): Promise<City | Object>;
    DelCity(payload: {
      id: string;
      name: string;
      utc: string;
    }): Promise<City | Object>;
    GetAllBuildingsList(): Promise<BuildingResponse.AsObject>;
    GetMerusoftBuildingsList(): Promise<LockerServiceBuildingsResponse.AsObject>;
    GetBuildingsList(name: string): Promise<BuildingResponse.AsObject>;
    AddBuilding(payload: {
      id: string;
      name: string;
      city: string;
      floorsList: string[];
      img: string;
      coworkingtotalseats?: string;
      issmartoffice?: boolean;
      smartofficeid?: number;
    }): Promise<Building | Object>;
    UpdBuilding(payload: {
      id: string;
      name: string;
      city: string;
      floorsList: string[];
      img: string;
      coworkingtotalseats?: string;
      issmartoffice?: boolean;
      smartofficeid?: number;
    }): Promise<Building | Object>;
    DelBuilding(payload: {
      id: string;
      name: string;
      city: string;
      floorsList: string[];
      img: string;
      coworkingtotalseats?: string;
      issmartoffice?: boolean;
      smartofficeid?: number;
    }): Promise<Building | Object>;
  };
  room: {
    GetRoomsList(
      city: string,
      building: string
    ): Promise<RoomResponse.AsObject>;
    CheckBookingRegularCollision(payload: {
      room: string;
      from: string;
      till: string;
    }): Promise<RoomCheckedList.AsObject>;
    GetAllRoomsList(): Promise<RoomResponse.AsObject>;
    SelIdRoom(id: string): Promise<RoomResponse.AsObject>;
    AddRoom(payload: Room.AsObject): Promise<Room | Object>;
    UpdRoom(payload: Room.AsObject): Promise<Room | Object>;
    DelRoom(payload: Room.AsObject): Promise<Room | Object>;
  };
  seat: {
    GetAllSeatsList(): Promise<SeatResponse.AsObject>;
    SelIdSeat(id: string): Promise<SeatResponse.AsObject>;
    SelCoworkingIdSeats(id: string): Promise<SeatResponse.AsObject>;
    AddSeat(payload: Seat.AsObject): Promise<Seat | Object>;
    UpdSeat(payload: Seat.AsObject): Promise<Seat | Object>;
    DelSeat(payload: Seat.AsObject): Promise<Seat | Object>;
  };
  locker: {
    GetAllLockerList(): Promise<LockerResponse.AsObject>;
    SelIdLocker(id: string): Promise<LockerResponse.AsObject>;
    OpenLocker(id: string): Promise<void>;
    SelLockerCabinetIdLockers(id: string): Promise<LockerResponse.AsObject>;
    SelMerusoftLockerCabinetIdLockers(
      id: string
    ): Promise<LockerServiceLockersResponse.AsObject>;
    AddLocker(payload: Locker.AsObject): Promise<Locker | Object>;
    UpdLocker(payload: Locker.AsObject): Promise<Locker | Object>;
    DelLocker(payload: Locker.AsObject): Promise<Locker | Object>;
  };
  coworking: {
    GetCoworkingsList(
      city: string,
      building: string
    ): Promise<CoworkingResponse.AsObject>;
    GetAllCoworkingsList(): Promise<CoworkingResponse.AsObject>;
    SelIdCoworking(id: string): Promise<CoworkingResponse.AsObject>;
    AddCoworking(payload: Coworking.AsObject): Promise<Coworking | Object>;
    DelCoworking(payload: Coworking.AsObject): Promise<Coworking | Object>;
    UpdCoworking(payload: Coworking.AsObject): Promise<Coworking | Object>;
  };
  lockerCabinet: {
    GetLockerCabinetList(
      city: string,
      building: string
    ): Promise<CabinetResponse.AsObject>;
    GetMerusoftLockerCabinetList(
      payload: LockerServiceCabinetsRequest.AsObject
    ): Promise<LockerServiceCabinetsResponse.AsObject>;
    GetAllLockerCabinetList(): Promise<CabinetResponse.AsObject>;
    SelIdLockerCabinet(id: string): Promise<CabinetResponse.AsObject>;
    AddLockerCabinet(payload: Cabinet.AsObject): Promise<Cabinet | Object>;
    DelLockerCabinet(payload: Cabinet.AsObject): Promise<Cabinet | Object>;
    UpdLockerCabinet(payload: Cabinet.AsObject): Promise<Cabinet | Object>;
  };
  booking: {
    GetBookingsList(date: string): Promise<BookingResponse.AsObject>;
    GetBookingsExpiredList(payload: {
      limit?: number;
      offset?: number;
    }): Promise<BookingResponse.AsObject>;
    GetBookingsExpiredListAll(payload: {
      limit?: number;
      offset?: number;
      search?: string;
      date?: string;
      city?: string;
      building?: string;
      room?: string;
      onlywithout?: boolean;
    }): Promise<BookingResponse.AsObject>;
    GetBookingsFutureList(payload: {
      limit?: number;
      offset?: number;
    }): Promise<BookingResponse.AsObject>;
    GetBookingsFutureListAll(payload: {
      limit?: number;
      offset?: number;
      search?: string;
      date?: string;
      city?: string;
      building?: string;
      room?: string;
      onlywithout?: boolean;
    }): Promise<BookingResponse.AsObject>;
    AddBooking(payload: {
      id: string;
      room: string;
      desc: string;
      date: string;
      from: string;
      till: string;
      img: string;
      regular: boolean;
      notification: number;
      canceled: boolean;
      outList: any;
      host: string;
      membersList: any;
      copy: string;
      type?: string;
      seat?: string;
      locker?: string;
      lastopen?: string;
      renewals?: number;
    }): Promise<Booking | Object>;
    UpdBooking(payload: {
      id: string;
      room: string;
      desc: string;
      date: string;
      from: string;
      till: string;
      img: string;
      regular: boolean;
      notification: number;
      canceled: boolean;
      outList: any;
      host: string;
      membersList: any;
      copy: string;
      type?: string;
      seat?: string;
      locker?: string;
      lastopen?: string;
      renewals?: number;
    }): Promise<Booking | Object>;
    UpdCancelBooking(payload: {
      id: string;
      room: string;
      desc: string;
      date: string;
      from: string;
      till: string;
      img: string;
      regular: boolean;
      notification: number;
      canceled: boolean;
      outList: any;
      host: string;
      membersList: any;
      copy: string;
      type?: string;
      seat?: string;
      locker?: string;
      lastopen?: string;
      renewals?: number;
    }): Promise<void>;
    SelIdBooking(id: string): Promise<BookingResponse.AsObject>;
    AddLostCopies(): Promise<void>;
    DelLostCopies(): Promise<void>;
  };
  equipment: {
    GetEquipmentsList(): Promise<EquipmentResponse.AsObject>;
  };
  employee: {
    SearchEmployee(payload: {
      q?: string;
      limit?: number;
      offset?: number;
    }): Promise<EmployeeListResponse.AsObject>;
    EmployeeByCode(payload: {
      code?: string;
    }): Promise<EmployeeResponse.AsObject>;
  };
  link: {
    GetLinkPayload(
      payload: AppPayloadRequest.AsObject
    ): Promise<AppPayloadResponse.AsObject>;
  };
  s3: {
    Upload(payload: {
      link: string;
      fileName: string;
      mime?: string;
      istempurl?: boolean;
    }): Promise<LinkResponse.AsObject>;
    GetLinkPerm(fileName: string): Promise<LinkResponse.AsObject>;
  };
  map: {
    AddMap(payload: {
      id: string;
      building: string;
      floor: string;
      map: string;
      type?: string;
    }): Promise<Map | Object>;
    UpdMap(payload: {
      id: string;
      building: string;
      floor: string;
      map: string;
      type?: string;
    }): Promise<Map | Object>;
    DelMap(payload: {
      id: string;
      building: string;
      floor: string;
      map: string;
      type?: string;
    }): Promise<Map | Object>;
    SelMap(
      building: string,
      floor: string,
      type?: string
    ): Promise<MapResponse.AsObject>;
  };
  chat: {
    GetChatsList(): Promise<ChatResponse.AsObject>;
  };
}

export default class Infrastructure implements iInfrastructure {
  auth = {
    GetTokenByCode(code: string) {
      return import("@/infrastructure/auth/get-token-by-code").then(
        (module) => {
          return module.GetTokenByCode(code);
        }
      );
    },
    RefreshToken() {
      return import("@/infrastructure/auth/refresh-token").then((module) => {
        return module.RefreshToken();
      });
    },
    GetRedirectUrl() {
      return import("@/infrastructure/auth/get-redirect-url").then((module) => {
        return module.GetRedirectUrl();
      });
    }
  };
  user = {
    CheckUserRole(code: string, role: string) {
      return import("@/infrastructure/user/check-user-role").then((module) => {
        return module.CheckUserRole(code, role);
      });
    },
    GetUsersList() {
      return import("@/infrastructure/user/get-users-list").then((module) => {
        return module.GetUsersList();
      });
    },
    SelUsers(payload: { code: string }) {
      return import("@/infrastructure/user/sel-users").then((module) => {
        return module.SelUsers(payload);
      });
    }
  };
  location = {
    GetCitiesList() {
      return import("@/infrastructure/city/get-cities-list").then((module) => {
        return module.GetCitiesList();
      });
    },
    AddCity(payload: { id: string; name: string; utc: string }) {
      return import("@/infrastructure/city/add-city").then((module) => {
        return module.AddCity(payload);
      });
    },
    UpdCity(payload: { id: string; name: string; utc: string }) {
      return import("@/infrastructure/city/upd-city").then((module) => {
        return module.UpdCity(payload);
      });
    },
    DelCity(payload: { id: string; name: string; utc: string }) {
      return import("@/infrastructure/city/del-city").then((module) => {
        return module.DelCity(payload);
      });
    },
    GetAllBuildingsList() {
      return import("@/infrastructure/building/get-all-buildings-list").then(
        (module) => {
          return module.GetAllBuildingsList();
        }
      );
    },
    GetMerusoftBuildingsList() {
      return import(
        "@/infrastructure/building/get-merusoft-buildings-list"
      ).then((module) => {
        return module.GetMerusoftBuildingsList();
      });
    },
    GetBuildingsList(name: string) {
      return import("@/infrastructure/building/get-buildings-list").then(
        (module) => {
          return module.GetBuildingsList(name);
        }
      );
    },
    AddBuilding(payload: {
      id: string;
      name: string;
      city: string;
      floorsList: string[];
      img: string;
      coworkingtotalseats?: string;
      issmartoffice?: boolean;
      smartofficeid?: number;
    }) {
      return import("@/infrastructure/building/add-building").then((module) => {
        return module.AddBuilding(payload);
      });
    },
    UpdBuilding(payload: {
      id: string;
      name: string;
      city: string;
      floorsList: string[];
      img: string;
      coworkingtotalseats?: string;
      issmartoffice?: boolean;
      smartofficeid?: number;
    }) {
      return import("@/infrastructure/building/upd-building").then((module) => {
        return module.UpdBuilding(payload);
      });
    },
    DelBuilding(payload: {
      id: string;
      name: string;
      city: string;
      floorsList: string[];
      img: string;
      coworkingtotalseats?: string;
      issmartoffice?: boolean;
      smartofficeid?: number;
    }) {
      return import("@/infrastructure/building/del-building").then((module) => {
        return module.DelBuilding(payload);
      });
    }
  };
  room = {
    GetRoomsList(city: string, building: string) {
      return import("@/infrastructure/room/get-rooms-list").then((module) => {
        return module.GetRoomsList(city, building);
      });
    },
    GetAllRoomsList() {
      return import("@/infrastructure/room/get-all-rooms-list").then(
        (module) => {
          return module.GetAllRoomsList();
        }
      );
    },
    SelIdRoom(id: string) {
      return import("@/infrastructure/room/sel-id-room").then((module) => {
        return module.SelIdRoom(id);
      });
    },
    CheckBookingRegularCollision(payload: {
      room: string;
      from: string;
      till: string;
    }) {
      return import(
        "@/infrastructure/room/check-booking-regular-collision"
      ).then((module) => {
        return module.CheckBookingRegularCollision(payload);
      });
    },
    AddRoom(payload: Room.AsObject) {
      return import("@/infrastructure/room/add-room").then((module) => {
        return module.AddRoom(payload);
      });
    },
    UpdRoom(payload: Room.AsObject) {
      return import("@/infrastructure/room/upd-room").then((module) => {
        return module.UpdRoom(payload);
      });
    },
    DelRoom(payload: Room.AsObject) {
      return import("@/infrastructure/room/del-room").then((module) => {
        return module.DelRoom(payload);
      });
    }
  };
  seat = {
    GetAllSeatsList() {
      return import("@/infrastructure/seat/get-all-seat-list").then(
        (module) => {
          return module.GetAllSeatsList();
        }
      );
    },
    SelIdSeat(id: string) {
      return import("@/infrastructure/seat/sel-id-seat").then((module) => {
        return module.SelIdSeat(id);
      });
    },
    SelCoworkingIdSeats(id: string) {
      return import("@/infrastructure/seat/sel-coworking-id-seats").then(
        (module) => {
          return module.SelCoworkingIdSeats(id);
        }
      );
    },
    AddSeat(payload: Seat.AsObject) {
      return import("@/infrastructure/seat/add-seat").then((module) => {
        return module.AddSeat(payload);
      });
    },
    UpdSeat(payload: Seat.AsObject) {
      return import("@/infrastructure/seat/upd-seat").then((module) => {
        return module.UpdSeat(payload);
      });
    },
    DelSeat(payload: Seat.AsObject) {
      return import("@/infrastructure/seat/del-seat").then((module) => {
        return module.DelSeat(payload);
      });
    }
  };
  locker = {
    GetAllLockerList() {
      return import("@/infrastructure/locker/get-all-locker-list").then(
        (module) => {
          return module.GetAllLockerList();
        }
      );
    },
    SelIdLocker(id: string) {
      return import("@/infrastructure/locker/sel-id-locker").then((module) => {
        return module.SelIdLocker(id);
      });
    },
    OpenLocker(id: string) {
      return import("@/infrastructure/locker/open-locker").then((module) => {
        return module.OpenLocker(id);
      });
    },
    SelLockerCabinetIdLockers(id: string) {
      return import(
        "@/infrastructure/locker/sel-locker-cabinet-id-lockers"
      ).then((module) => {
        return module.SelLockerCabinetIdLockers(id);
      });
    },
    SelMerusoftLockerCabinetIdLockers(id: string) {
      return import(
        "@/infrastructure/locker/sel-merusoft-locker-cabinet-id-lockers"
      ).then((module) => {
        return module.SelMerusoftLockerCabinetIdLockers(id);
      });
    },
    AddLocker(payload: Locker.AsObject) {
      return import("@/infrastructure/locker/add-locker").then((module) => {
        return module.AddLocker(payload);
      });
    },
    UpdLocker(payload: Locker.AsObject) {
      return import("@/infrastructure/locker/upd-locker").then((module) => {
        return module.UpdLocker(payload);
      });
    },
    DelLocker(payload: Locker.AsObject) {
      return import("@/infrastructure/locker/del-locker").then((module) => {
        return module.DelLocker(payload);
      });
    }
  };
  coworking = {
    GetCoworkingsList(city: string, building: string) {
      return import("@/infrastructure/coworking/get-coworking-list").then(
        (module) => {
          return module.GetCoworkingsList(city, building);
        }
      );
    },
    GetAllCoworkingsList() {
      return import("@/infrastructure/coworking/get-all-coworking-list").then(
        (module) => {
          return module.GetAllCoworkingsList();
        }
      );
    },
    SelIdCoworking(id: string) {
      return import("@/infrastructure/coworking/sel-id-coworking").then(
        (module) => {
          return module.SelIdCoworking(id);
        }
      );
    },
    AddCoworking(payload: Coworking.AsObject) {
      return import("@/infrastructure/coworking/add-coworking").then(
        (module) => {
          return module.AddCoworking(payload);
        }
      );
    },
    DelCoworking(payload: Coworking.AsObject) {
      return import("@/infrastructure/coworking/del-coworking").then(
        (module) => {
          return module.DelCoworking(payload);
        }
      );
    },
    UpdCoworking(payload: Coworking.AsObject) {
      return import("@/infrastructure/coworking/upd-coworking").then(
        (module) => {
          return module.UpdCoworking(payload);
        }
      );
    }
  };
  lockerCabinet = {
    GetLockerCabinetList(city: string, building: string) {
      return import(
        "@/infrastructure/locker-cabinet/get-locker-cabinet-list"
      ).then((module) => {
        return module.GetLockerCabinetList(city, building);
      });
    },
    GetMerusoftLockerCabinetList(
      payload: LockerServiceCabinetsRequest.AsObject
    ) {
      return import(
        "@/infrastructure/locker-cabinet/get-merusoft-locker-cabinet-list"
      ).then((module) => {
        return module.GetMerusoftLockerCabinetList(payload);
      });
    },
    GetAllLockerCabinetList() {
      return import(
        "@/infrastructure/locker-cabinet/get-all-locker-cabinet-list"
      ).then((module) => {
        return module.GetAllLockerCabinetList();
      });
    },
    SelIdLockerCabinet(id: string) {
      return import(
        "@/infrastructure/locker-cabinet/sel-id-locker-cabinet"
      ).then((module) => {
        return module.SelIdLockerCabinet(id);
      });
    },
    AddLockerCabinet(payload: Cabinet.AsObject) {
      return import("@/infrastructure/locker-cabinet/add-locker-cabinet").then(
        (module) => {
          return module.AddLockerCabinet(payload);
        }
      );
    },
    DelLockerCabinet(payload: Cabinet.AsObject) {
      return import("@/infrastructure/locker-cabinet/del-locker-cabinet").then(
        (module) => {
          return module.DelLockerCabinet(payload);
        }
      );
    },
    UpdLockerCabinet(payload: Cabinet.AsObject) {
      return import("@/infrastructure/locker-cabinet/upd-locker-cabinet").then(
        (module) => {
          return module.UpdLockerCabinet(payload);
        }
      );
    }
  };
  booking = {
    GetBookingsList(date: string) {
      return import("@/infrastructure/booking/get-bookings-list").then(
        (module) => {
          return module.GetBookingsList(date);
        }
      );
    },
    GetBookingsExpiredList(payload: { limit?: number; offset?: number }) {
      return import("@/infrastructure/booking/get-bookings-expired-list").then(
        (module) => {
          return module.GetBookingsExpiredList(payload);
        }
      );
    },
    GetBookingsExpiredListAll(payload: {
      limit?: number;
      offset?: number;
      search?: string;
      date?: string;
      city?: string;
      building?: string;
      room?: string;
      onlywithout?: boolean;
    }) {
      return import(
        "@/infrastructure/booking/get-bookings-expired-list-all"
      ).then((module) => {
        return module.GetBookingsExpiredListAll(payload);
      });
    },
    GetBookingsFutureList(payload: { limit?: number; offset?: number }) {
      return import("@/infrastructure/booking/get-bookings-future-list").then(
        (module) => {
          return module.GetBookingsFutureList(payload);
        }
      );
    },
    GetBookingsFutureListAll(payload: {
      limit?: number;
      offset?: number;
      search?: string;
      date?: string;
      city?: string;
      building?: string;
      room?: string;
      onlywithout?: boolean;
    }) {
      return import(
        "@/infrastructure/booking/get-bookings-future-list-all"
      ).then((module) => {
        return module.GetBookingsFutureListAll(payload);
      });
    },
    AddBooking(payload: {
      id: string;
      room: string;
      desc: string;
      date: string;
      from: string;
      till: string;
      img: string;
      regular: boolean;
      notification: number;
      canceled: boolean;
      outList: any;
      host: string;
      membersList: any;
      copy: string;
      type?: string;
      seat?: string;
      locker?: string;
      lastopen?: string;
      renewals?: number;
    }) {
      return import("@/infrastructure/booking/add-booking").then((module) => {
        return module.AddBooking(payload);
      });
    },
    UpdBooking(payload: {
      id: string;
      room: string;
      desc: string;
      date: string;
      from: string;
      till: string;
      img: string;
      regular: boolean;
      notification: number;
      canceled: boolean;
      outList: any;
      host: string;
      membersList: any;
      copy: string;
      type?: string;
      seat?: string;
      locker?: string;
      lastopen?: string;
      renewals?: number;
    }) {
      return import("@/infrastructure/booking/upd-booking").then((module) => {
        return module.UpdBooking(payload);
      });
    },
    UpdCancelBooking(payload: {
      id: string;
      room: string;
      desc: string;
      date: string;
      from: string;
      till: string;
      img: string;
      regular: boolean;
      notification: number;
      canceled: boolean;
      outList: any;
      host: string;
      membersList: any;
      copy: string;
      type?: string;
      seat?: string;
      locker?: string;
      lastopen?: string;
      renewals?: number;
    }) {
      return import("@/infrastructure/booking/upd-cancel-booking").then(
        (module) => {
          return module.UpdCancelBooking(payload);
        }
      );
    },
    SelIdBooking(id: string) {
      return import("@/infrastructure/booking/sel-id-booking").then(
        (module) => {
          return module.SelIdBooking(id);
        }
      );
    },
    AddLostCopies() {
      return import("@/infrastructure/booking/add-lost-copies").then(
        (module) => {
          return module.AddLostCopies();
        }
      );
    },
    DelLostCopies() {
      return import("@/infrastructure/booking/del-lost-copies").then(
        (module) => {
          return module.DelLostCopies();
        }
      );
    }
  };
  equipment = {
    GetEquipmentsList() {
      return import("@/infrastructure/equipment/get-equipments-list").then(
        (module) => {
          return module.GetEquipmentsList();
        }
      );
    }
  };
  employee = {
    SearchEmployee(payload: { q?: string; limit?: number; offset?: number }) {
      return import("@/infrastructure/employee/search-employee").then(
        (module) => {
          return module.SearchEmployee(payload);
        }
      );
    },
    EmployeeByCode(payload: { code: string }) {
      return import("@/infrastructure/employee/employee-by-code").then(
        (module) => {
          return module.EmployeeByCode(payload);
        }
      );
    }
  };

  link = {
    GetLinkPayload(payload: AppPayloadRequest.AsObject) {
      return import("@/infrastructure/link/get-link-payload").then((module) => {
        return module.GetLinkPayload(payload);
      });
    }
  };
  s3 = {
    Upload(payload: {
      link: string;
      fileName: string;
      mime?: string;
      istempurl?: boolean;
    }) {
      return import("@/infrastructure/s3/upload").then((module) => {
        return module.Upload(payload);
      });
    },
    GetLinkPerm(fileName: string) {
      return import("@/infrastructure/s3/get-link-perm").then((module) => {
        return module.GetLinkPerm(fileName);
      });
    }
  };
  map = {
    AddMap(payload: {
      id: string;
      building: string;
      floor: string;
      map: string;
      type?: string;
    }) {
      return import("@/infrastructure/map/add-map").then((module) => {
        return module.AddMap(payload);
      });
    },
    UpdMap(payload: {
      id: string;
      building: string;
      floor: string;
      map: string;
      type?: string;
    }) {
      return import("@/infrastructure/map/upd-map").then((module) => {
        return module.UpdMap(payload);
      });
    },
    DelMap(payload: {
      id: string;
      building: string;
      floor: string;
      map: string;
      type?: string;
    }) {
      return import("@/infrastructure/map/del-map").then((module) => {
        return module.DelMap(payload);
      });
    },
    SelMap(building: string, floor: string, type?: string) {
      return import("@/infrastructure/map/sel-map").then((module) => {
        return module.SelMap(building, floor, type);
      });
    }
  };
  chat = {
    GetChatsList() {
      return import("@/infrastructure/chat/get-chats-list").then((module) => {
        return module.GetChatsList();
      });
    }
  };
}
