/* eslint-disable no-console */
import store from "@/store";
import Infrastructure from "@/infrastructure";
import Router from "../router";
import moment from "moment";
const infra = new Infrastructure();

export default {
  namespaced: true,
  state: {
    actualBooking: [] as any,
    historyBooking: [] as any
  },
  mutations: {
    addActualBookingsList(state: any, arr) {
      state.actualBooking = arr;
    },
    addHistoryBookingsList(state: any, arr) {
      state.historyBooking = arr;
    },
    cancelActualBooking(state: any, id: string) {
      state.actualBooking = state.actualBooking.filter((b) => {
        return b.id !== id;
      });
    }
  },
  actions: {
    async loadActualBookingsList(
      s: any,
      payload: { limit?: number; offset?: number }
    ): Promise<void> {
      await infra.booking
        .GetBookingsFutureList(payload)
        .then((response) => {
          s.commit("addActualBookingsList", response.bookingList);
        })
        .catch((error) => {
          console.log(error);
          store.commit("setNetworkError");
        });
    },
    async loadHistoryBookingsList(
      s: any,
      payload: { limit?: number; offset?: number }
    ): Promise<void> {
      await infra.booking
        .GetBookingsExpiredList(payload)
        .then((response) => {
          s.commit("addHistoryBookingsList", response.bookingList);
        })
        .catch((error) => {
          console.log(error);
          store.commit("setNetworkError");
        });
    }
  },
  getters: {
    actualBooking: (s: any) => s.actualBooking,
    historyBooking: (s: any) =>
      s.historyBooking.sort(
        (a, b) =>
          +moment(b.from).toDate().getTime() -
          +moment(a.from).toDate().getTime()
      )
  }
};
