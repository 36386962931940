import IBuilding from "@/models/building";

export default {
  state: {
    isFiltered: false,
    currentFloor: "1",
    settings: {
      city: { id: "", name: "Город" },
      building: { id: "", city: "", name: "Здание", floorsList: [] },
      equipment: []
    },
    settingsBO: {
      city: { id: "", name: "Город" },
      building: { id: "", city: "", name: "Здание", floorsList: [] },
      equipment: []
    },
    cities: [],
    buildings: []
  },
  getters: {
    getCurrentFloor: (s: any) => s.currentFloor,
    selectedBuilding(state) {
      return `${state.settings.city.name}, ${
        state.settings.building.name ? state.settings.building.name : "Здание"
      }`;
    },
    filteredCityBuildings(state) {
      return state.buildings.filter((b) => b.city === state.settings.city.id);
    },
    cities: (s: any) => s.cities,
    settings: (s: any) => s.settings,
    settingsBO: (s: any) => s.settingsBO,
    floors: (s: any) => s.settings.building.floorsList,
    isFiltered: (s: any) => s.isFiltered
  },
  mutations: {
    setCurrentFloor(state, payload: string) {
      state.currentFloor = payload;
    },
    setFilterActive(state, payload) {
      state.isFiltered = payload;
    },
    setFilterCity(state, payload) {
      state.settings = { ...state.settings, city: payload };
    },
    setFilterBuilding(state, payload) {
      state.settings.building = payload;
    },
    setFilterCityBO(state, payload) {
      state.settingsBO = { ...state.settingsBO, city: payload };
    },
    setFilterBuildingBO(state, payload) {
      state.settingsBO.building = payload;
    },
    setFilterEquipment(state, payload) {
      state.settings = { ...state.settings, equipment: payload };
    }
  },
  namespaced: true
};
